// 3rd party
import { useRef, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

// Redux
import {
  updateCartModal,
  updateCreateModal,
  updateLoginModal,
  updateResetPasswordModal,
} from '@/redux/actions/uiAction'
import { getCustomer, logoutCustomer } from '@/redux/actions/userAction'
import { getCart } from '@/redux/actions/cartAction'

// Config
import { server } from '@/config/server'
import { enableMenuPreview, enablePromobarPreview } from '@/lib/contentful/module'
import { getPromobar } from '@/lib/contentful/promobar'
import { getSuggestions, getAutocompleteData } from '@/lib/searchspring'

// Helpers
import { enableBodyScroll } from '@/lib/helpers/body-scroll'

// Components
// import MobileHeader from './Menu/MobileMenu/MobileHeader'
// import WebHeader from './Menu/WebMenu/WebHeader'
// import Promobar from '@/components/Promobar/Promobar'

// Context
import { useMobileSearchContext } from '@/components/context/MobileSearchContext'
import { disableBodyScroll, enableBodyScrollCart, getHeaderMenu } from '@/lib/helper'
import dynamic from 'next/dynamic'
import RouteContext from '@/components/context/routerContext'

const MobileHeader = dynamic(() => import('./Menu/MobileMenu/MobileHeader'))
const WebHeader = dynamic(() => import('./Menu/WebMenu/WebHeader'))
const Promobar = dynamic(() => import('@/components/Promobar/Promobar'))
const DynamicCreateAccountModal = dynamic(
  () => import('@/components/organisms/CreateAccountModal').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const DynamicCartModal = dynamic(
  () => import('./Menu/Common/CartModal').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const DynamicSignInModal = dynamic(
  () => import('@/components/SignInModal/SignInModal').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import MobileHeader from './Menu/MobileMenu/MobileHeader';

const Header = ({
  hideSearch = false,
  hideSubMenu = false,
  isOpenModal,
  loadingUrl,
  menu,
  navigateSystemBuilder,
  preview,
  setIsOpenModal,
  setModal,
  setNavigateSystemBuilder,
  setPayment,
}) => {
  let promobar = getPromobar()
  const showPromobar = promobar && promobar.fields.show ? <Promobar {...promobar.fields} /> : null
  const { mobileSearch, setMobileSearch } = useMobileSearchContext()
  const router = useRouter()
  const headerData = getHeaderMenu()
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cartReducer)
  const customer = useSelector((state) => state.userReducer)
  const ui = useSelector((state) => state.uiReducer)
  const hasRun = useRef(false);
  const [item, setItem] = useState(headerData)
  const [isOpenMenuModal, setIsOpenMenuModal] = useState(false)
  const [isCartModalOpen, setIsCartModalOpen] = useState(false)
  const [search, setSearch] = useState({
    requestQuery: false,
    request: false,
    loading: false,
    data: {},
    categories: [],
    suggest: null,
  })

  const routes = router?.asPath.split('?')
  let logoutval = ''
  routes.length > 0 &&
    routes.forEach((item) => {
      if (item.split('=')[0] == 'logout') {
        logoutval = item.split('=')[1]
      }
    })

  const messageText = promobar.fields?.message?.content[0]?.content[0]?.value || ''
  const [promobarData, setPromobarData] = useState(showPromobar)
  const openSignInModal = router.query && router.query['create-an-account'] //create-an-account=true
  const openResetPasswordModal = router.query && router.query['reset-password'] //create-an-account=true
  const openLoginModal = router.query && router.query['login'] //login=true
  const openCartModal = router.query && router.query['cart'] // cart=true
  const openLogOutModal = logoutval // logout=true
  const isClient = RouteContext && useContext(RouteContext)?.isClient

  useEffect(() => {
    if(!isOpenMenuModal)
      enableBodyScrollCart();

    if (preview) {
      let mounted = true

      const getMenu = async () => {
        const res = await enableMenuPreview(menu, preview)
        if (mounted) {
          setItem({ ...res })
        }
      }

      getMenu()

      return () => {
        mounted = false
      }
    }
  }, [])

  useEffect(() => {
    enableBodyScroll(!isOpenMenuModal)
  }, [isOpenMenuModal])

  useEffect(() => {
    // allow body scroll if the modal is closed or if we are searching
    const allowScroll = !isOpenModal || !!mobileSearch?.requestQuery
    enableBodyScroll(allowScroll)
  }, [isOpenModal])

  useEffect(() => {
    if (openResetPasswordModal && JSON.parse(openResetPasswordModal)) {
      enableBodyScroll()
      setResetPasswordModal(true)

      router.query['reset-password'] = 'false'
      router.push(router)
    }
  }, [openResetPasswordModal])

  useEffect(() => {
    if (openSignInModal && JSON.parse(openSignInModal)) {
      enableBodyScroll()
      setCreateAccountModal(true)

      router.query['create-an-account'] = 'false'
      router.push(router, undefined, { scroll: false })
    }
  }, [openSignInModal])

  useEffect(() => {
    if (openLoginModal && JSON.parse(openLoginModal)) {
      enableBodyScroll()
      signInModalHandler(true)

      router.query['login'] = 'false'
      router.push(router)
    }
  }, [openLoginModal])

  useEffect(() => {
    if (openCartModal && JSON.parse(openCartModal)) {
      enableBodyScroll()
      cartModalViewHandler()

      router.query['cart'] = 'false'
      router.push(router)
    }
  }, [openCartModal])

  useEffect(() => {
    if (openLogOutModal && JSON.parse(openLogOutModal)) {
      customerLogout()
      router.query['logout'] = 'false'
      router.push(router)
    }
  }, [openCartModal])

  const customerLogout = async () => {
    const response = await logoutCustomer(dispatch)
    if (response?.checkoutURL) {
      router.replace(response?.checkoutURL)
    }
  }

  useEffect(() => {
    if (!customer.isAuthenticated) {
      getCustomer(dispatch)
    }
  }, [customer.isAuthenticated])

  useEffect(() => {
    if (isClient && cart.created_time === '') {
      getCart(dispatch, customer)
    }
  }, [cart.created_time, isClient])

  useEffect(() => {
    dispatch(updateCartModal(false))
  }, [])

  useEffect(() => {
    if (ui.cartModal !== isCartModalOpen) {
      setIsCartModalOpen(ui.cartModal)
    }
  }, [ui.cartModal])

  useEffect(() => {
    if (search.requestQuery || search.requestQuery.length > 2) {
      setSearch((prev) => ({ ...prev, loading: true }))

      onSearchSuggestionRequest(search.requestQuery)
    } else {
      onSearchSuggestionRequest(null)
    }

    if (!search.requestQuery || search.requestQuery.length <= 2)
      return setSearch((prev) => ({ ...prev, data: {}, request: false, loading: false }))

    let timer = setTimeout(() => setSearch((prev) => ({ ...prev, request: true })), 1 * 1000)

    return () => {
      clearTimeout(timer)

      setSearch((prev) => ({ ...prev, request: false }))
    }
  }, [search.requestQuery])

  useEffect(() => {
    if (search.request) {
      onSearchRequestApi(search.requestQuery).then((res) => {
        res && res.results && setSearch((prev) => ({ ...prev, data: res }))
      })

      setTimeout(() => {
        setSearch((prev) => ({ ...prev, loading: false }))
      }, 350)
    } else setSearch((prev) => ({ ...prev, suggest: null, loading: false }))
  }, [search.request])

  useEffect(async () => {
    if (preview) {
      const promoPreview = await enablePromobarPreview()
      if (promoPreview) {
        let promo = (
          <Promobar
            backgroundColor={promoPreview.backgroundColor}
            textColor={promoPreview.textColor}
            show={promoPreview.show}
            message={promoPreview.message.json}
          />
        )
        setPromobarData(promo)
      }
    }
  }, [])

  useEffect(() => {
    let existingDnsFlag = dataLayer.filter((layer) => layer.event == 'dns_flag')
    let lastDnsFlag = existingDnsFlag[existingDnsFlag.length - 1]
    if (customer?.promoOrderUser) {
      let dnsFlag = customer?.promoOrderUser?.dnsFlag
        ? `${customer?.promoOrderUser?.dnsFlag}`
        : 'false'
      let dnsEvent = {
        event: 'dns_flag',
        dnsFlag: `${dnsFlag}`,
        page_location: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
      }
      if (
        !lastDnsFlag ||
        lastDnsFlag.dnsFlag != dnsEvent.dnsFlag ||
        lastDnsFlag.page_location != dnsEvent.page_location
      ) {
        dataLayer.push(dnsEvent)
      }
    } else {
      let dnsEvent = {
        event: 'dns_flag',
        dnsFlag: `false`,
        page_location: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
      }
      if (
        !lastDnsFlag ||
        lastDnsFlag.dnsFlag != dnsEvent.dnsFlag ||
        lastDnsFlag.page_location != dnsEvent.page_location
      ) {
        dataLayer.push(dnsEvent)
      }
    }
    // if(customer?.promoOrderUser?.dnsFlag) {
    //   dnsFlag = `${customer?.promoOrderUser?.dnsFlag}`
    // }
    // let existingDnsFlag = dataLayer[dataLayer.length - 1]
    // if(existingDnsFlag && existingDnsFlag.event == 'dns_flag') {
    //   let dnsFlagValue = existingDnsFlag?.dnsFlag
    //   if(dnsFlag != dnsFlagValue) {
    //     console.log(dnsFlag+'1')
    //     dataLayer.push({
    //       event: 'dns_flag',
    //       dnsFlag: `${dnsFlag}`,
    //       location: router.asPath
    //     })
    //   }
    // } else {
    //   console.log(dnsFlag+'2')
    //   dataLayer.push({
    //     event: 'dns_flag',
    //     dnsFlag: `${dnsFlag}`,
    //     location: router.asPath
    //   })
    // }
  }, [router.asPath, customer?.promoOrderUser])

  const signInModalHandler = async (isOpen) => {
    enableBodyScroll(!isOpen)
    await dispatch(updateLoginModal(isOpen))
  }

  const setCreateAccountModal = async (isOpen) => {
    await dispatch(updateCreateModal(isOpen))
  }

  const setResetPasswordModal = async (isOpen) => {
    await dispatch(updateResetPasswordModal(isOpen))
  }

  const modalViewHandler = () => {
    setIsOpenModal(!isOpenModal)
    setMobileSearch((prev) => ({ ...prev, requestQuery: '' }))
  }

  const menuModalHandler = (isOpen) => {
    if (isOpen) {
      enableBodyScroll(true)
      setIsOpenMenuModal(false)
    } else {
      setIsOpenMenuModal(true)
      enableBodyScroll()
    }
  }

  const toggleMenu = (opened) => {
    if (opened) {
    } else {
      setIsOpenModal(false)
      setIsOpenMenuModal(false)
      enableBodyScroll(true)
    }
  }

  const cartModalViewHandler = async () => {
    setIsCartModalOpen((prev) => !prev)
    // enableBodyScroll(isCartModalOpen)
    if (isCartModalOpen) {
      enableBodyScrollCart()
    } else {
      disableBodyScroll()
    }
    await dispatch(updateCartModal(!isCartModalOpen))
  }

  const onSearchRequest = (e) => {
    if (e) {
      setSearch((prev) => ({ ...prev, requestQuery: e }))
    } else {
      setSearch((prev) => ({ ...prev, requestQuery: false }))
    }
  }

  const onSearchRequestApi = async (search) => {
    const data = await getAutocompleteData(search, 3)
    return data
  }

  // Web search logic only, mobile search logic is in <MobileSearchView />
  const onSearchSuggestionRequest = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 2)
      return setSearch((prev) => ({ ...prev, suggest: null }))

    // const response = await fetch(`${server}/api/searchspring/suggestion`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     query: searchTerm,
    //   }),
    // })
    // const data = await response.json()
    const data = await getSuggestions(searchTerm)
    if (data) setSearch((prev) => ({ ...prev, suggest: data }))

    return data
  }

  const { hasCart, hasLogin, searchPlaceholderText, subMenu } = item || {}
  return (
    <nav id="siteHeader" className="main-nav">
      <WebHeader
        cart={cart}
        cartModalViewHandler={cartModalViewHandler}
        customer={customer}
        hasCart={hasCart}
        hasLogin={hasLogin}
        hideSearch={hideSearch}
        hideSubMenu={hideSubMenu}
        isOpenMenuModal={isOpenMenuModal}
        setIsOpenMenuModal={setIsOpenMenuModal}
        menuModalHandler={menuModalHandler}
        modalViewHandler={modalViewHandler}
        navigateSystemBuilder={navigateSystemBuilder}
        preview={preview}
        search={search}
        searchPlaceholderText={searchPlaceholderText}
        searchRequest={onSearchRequest}
        setNavigateSystemBuilder={setNavigateSystemBuilder}
        setSearch={setSearch}
        signInModalHandler={signInModalHandler}
        subMenu={subMenu}
        showPromobar={promobarData}
      />
      <MobileHeader
        cart={cart}
        cartModalViewHandler={cartModalViewHandler}
        customer={customer}
        hasCart={hasCart}
        hasLogin={hasLogin}
        hideSearch={hideSearch}
        isOpenModal={isOpenModal}
        preview={preview}
        modalViewHandler={modalViewHandler}
        searchPlaceholderText={searchPlaceholderText}
        signInModalHandler={signInModalHandler}
        subMenu={subMenu}
        menu={menu}
        toggleMenu={toggleMenu}
        showPromobar={promobarData}
      />

      {/* Render login modal */}
      {ui.loginModal ? (
        <DynamicSignInModal
          customer={customer}
          loadingUrl={loadingUrl}
          setCreateAccountModal={setCreateAccountModal}
          setModal={setModal}
          setPayment={setPayment}
          setResetPasswordModal={setResetPasswordModal}
          signInModalHandler={signInModalHandler}
          toggleMenu={toggleMenu}
        />
      ) : null}

      {/* Render sign up modal */}
      {ui.createModal ? (
        <DynamicCreateAccountModal
          setCreateAccountModal={setCreateAccountModal}
          signInModalHandler={signInModalHandler}
          toggleMenu={toggleMenu}
        />
      ) : null}

      {/* Render cart modal */}
      {ui.cartModal ? (
        <DynamicCartModal
          cart={cart}
          cartModalViewHandler={cartModalViewHandler}
          customer={customer}
          isCartModalOpen={isCartModalOpen}
        />
      ) : null}
    </nav>
  )
}

export default Header
