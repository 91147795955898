import { createContext, useState, useEffect } from 'react'

const RouteContext = createContext()

export const RouteProvider = ({ children }) => {
  const [previousUrl, setPreviousUrl] = useState([])
  const [viewProduct, setViewProduct] = useState([])
  const [viewedPromotion, setViewedPromotion] = useState([])
  const [isClient, setIsClient] = useState(false)

  const PreviousUrlSet = (url) => {
    setPreviousUrl([...previousUrl, url])
  }

  useEffect(() => {
    if (typeof window != 'undefined') {
      setIsClient(true)
    }
  }, [])

  const valueToShare = {
    PreviousUrlSet,
    previousUrl,
    viewProduct,
    setViewProduct,
    viewedPromotion,
    setViewedPromotion,
    isClient,
  }

  return <RouteContext.Provider value={valueToShare}>{children}</RouteContext.Provider>
}

export default RouteContext
